import React, {useState} from "react";
import UserName from "../../components/username/UserName";
import UserTickets from "../../components/user-tickets/UserTickets";
import UserKeys from "../../components/user-keys/UserKeys";
import { ReactComponent as Arw } from "../../img/ar.svg";
import { ReactComponent as YouTube } from "../../img/List Item/Left/Items card/YouTube.svg";
import { ReactComponent as Tg } from "../../img/List Item/Left/Items card/Telegram.svg";
import { ReactComponent as Twitter } from "../../img/List Item/Left/Items card/Twitter.svg";
import { ReactComponent as Coin } from "../../img/coin.svg";
import { ReactComponent as Coin1 } from "../../img/coin-1.svg";
import { ReactComponent as Pluss } from "../../img/Actions/Plus.svg";
import { ReactComponent as Block } from "../../img/Block.svg";
import { ReactComponent as Ref } from "../../img/Nav bar/Icon-1.svg";
import li from "../../img/Bg light top.png";
import { ReactComponent as Key } from "../../img/Key.svg";
import { ReactComponent as Jetton } from "../../img/Jetton.svg";
import { ReactComponent as X } from "../../img/x.svg";
import "./tasks.css";
import { useSelector, useDispatch} from "react-redux";
import axios from "axios";

const Tasks = ({setShowAlert, alert, onHandleNavbar}) => {
  const [showKey, setShowKey] = useState(false);
  const [showTickets, setShowTickets] = useState(false);
  const [claim, setClaim] = useState([])

  const [openTask1, setOpenTask1] = useState(false)
  const [openTask2, setOpenTask2] = useState(false)
  const [openTask3, setOpenTask3] = useState(false)
  const [openTask4, setOpenTask4] = useState(false)
  const [openTask5, setOpenTask5] = useState(false)
  const [openTask6, setOpenTask6] = useState(false)
  const [goBack, setGoBack] = useState(false);

  const tasks = useSelector(state => state.currentUser.tasks)
  const telegramId = useSelector(state => state.currentUser.telegramId)
  const balance = useSelector(state => state.currentUser.balance)
  const keys = useSelector(state => state.currentUser.keys)
  const id = useSelector(state => state.currentUser.id)
  const dispatch = useDispatch();

  const completeTask = async (taskId) => {
    try {
      const {data} = await axios.post(`https://niseapp.lol/api/task/create/${id}/${taskId}`);
      if(data.success) {
        await axios.put(`https://niseapp.lol/api/user/change-balance/${telegramId}`, {
          newBalance: balance + 500 
        });
        await axios.put(`https://niseapp.lol/api/user/change-key/${telegramId}`, {
          newKeyBalance: Math.floor((keys + 0.1) * 10) / 10
        });
        if (!alert) {
          setShowAlert(true);
          setTimeout(() => setShowAlert(false), "3000");
        }
        dispatch({
          type: "change-balance",
          payload: {
            balance: balance + 500
          }
        })
        dispatch({
          type: "change-keys",
          payload: {
            keys: Math.floor((keys + 0.1) * 10) / 10
          }
        })
        dispatch({
          type: "change-tasks",
          payload: {
            tasks: [...tasks, {id: Math.random(), task: taskId, user: String(telegramId)}]
          }
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      {openTask1 && (
        <>
          <div
            className="black-zone"
            onClick={() => {
              setGoBack(true);
              setTimeout(() => {
                setOpenTask1(false);
                onHandleNavbar();
                setGoBack(false);
              }, "500");
            }}
            style={goBack ? { display: "none" } : null}
          ></div>
          <div
            className="window"
            style={
              goBack
                ? {
                    animation: "window_back 0.6s",
                    bottom: "-300px",
                  }
                : null
            }
          >
            <div className="window_data">
              <img src={li} width={"100%"} alt="" />
              <div style={{ padding: "16px", height: "100%" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <X
                  className="close_x"
                    style={{ opacity: "12%" }}
                    onClick={() => {
                      setGoBack(true);
                      setTimeout(() => {
                        setOpenTask1(false);
                        onHandleNavbar();
                        setGoBack(false);
                      }, "500");
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "relative",
                    height: "100%",
                    textAlign: "center",
                  }}
                >
                  <div>
                    <YouTube style={{width: '80px', height: '80px'}}/>
                    <p className="wnd_header">Follow Nise YouTube</p>
                    <span className="wnd_text">
                    You can invite friends through your referral link and get bonuses
                    </span>
                  </div>
                  {
                    claim.includes(1) ? (
                      <button className="by" disabled={tasks.some(e => e.task === 1)} onClick={() => {
                        completeTask(1)
                        setGoBack(true);
                        setTimeout(() => {
                          setOpenTask1(false);
                          onHandleNavbar();
                          setGoBack(false);
                        }, "500");
                        }}>
                      Claim
                    </button>
                    ) : (
                      <a href="https://www.youtube.com/@niseapp" target="_blank" className="by" onClick={() => {
                        setTimeout(() =>  setClaim([...claim, 1]), 2000)
                      }}>Follow</a>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {openTask2 && (
        <>
          <div
            className="black-zone"
            onClick={() => {
              setGoBack(true);
              setTimeout(() => {
                setOpenTask2(false);
                onHandleNavbar();
                setGoBack(false);
              }, "500");
            }}
            style={goBack ? { display: "none" } : null}
          ></div>
          <div
            className="window"
            style={
              goBack
                ? {
                    animation: "window_back 0.6s",
                    bottom: "-300px",
                  }
                : null
            }
          >
            <div className="window_data">
              <img src={li} width={"100%"} alt="" />
              <div style={{ padding: "16px", height: "100%" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <X
                  className="close_x"
                    style={{ opacity: "12%" }}
                    onClick={() => {
                      setGoBack(true);
                      setTimeout(() => {
                        setOpenTask2(false);
                        onHandleNavbar();
                        setGoBack(false);
                      }, "500");
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "relative",
                    height: "100%",
                    textAlign: "center",
                  }}
                >
                  <div>
                    <Tg style={{width: '80px', height: '80px'}}/>
                    <p className="wnd_header">Subscribe Nise</p>
                    <span className="wnd_text">
                    You can invite friends through your referral link and get bonuses
                    </span>
                  </div>
                  {
                    claim.includes(2) ? (
                      <button className="by" disabled={tasks.some(e => e.task === 2)}  onClick={() => {
                        completeTask(2)
                        setGoBack(true);
                        setTimeout(() => {
                          setOpenTask2(false);
                          onHandleNavbar();
                          setGoBack(false);
                        }, "500");
                      }}>
                      Claim
                    </button>
                    ) : (
                      <a href="https://t.me/niseapp" target="_blank" className="by" onClick={() => {
                        setTimeout(() =>  setClaim([...claim, 2]), 2000)
                      }}>Subscribe </a>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </>
      )}
            {openTask3 && (
        <>
          <div
            className="black-zone"
            onClick={() => {
              setGoBack(true);
              setTimeout(() => {
                setOpenTask3(false);
                onHandleNavbar();
                setGoBack(false);
              }, "500");
            }}
            style={goBack ? { display: "none" } : null}
          ></div>
          <div
            className="window"
            style={
              goBack
                ? {
                    animation: "window_back 0.6s",
                    bottom: "-300px",
                  }
                : null
            }
          >
            <div className="window_data">
              <img src={li} width={"100%"} alt="" />
              <div style={{ padding: "16px", height: "100%" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <X
                  className="close_x"
                    style={{ opacity: "12%" }}
                    onClick={() => {
                      setGoBack(true);
                      setTimeout(() => {
                        setOpenTask3(false);
                        onHandleNavbar();
                        setGoBack(false);
                      }, "500");
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "relative",
                    height: "100%",
                    textAlign: "center",
                  }}
                >
                  <div>
                    <Tg style={{width: '80px', height: '80px'}}/>
                    <p className="wnd_header">Subscribe DA</p>
                    <span className="wnd_text">
                    You can invite friends through your referral link and get bonuses
                    </span>
                  </div>
                  {
                    claim.includes(3) ? (
                      <button className="by" disabled={tasks.some(e => e.task === 3)}  onClick={() => {
                        completeTask(3)
                        setGoBack(true);
                        setTimeout(() => {
                          setOpenTask3(false);
                          onHandleNavbar();
                          setGoBack(false);
                        }, "500");
                        }}>
                      Claim
                    </button>
                    ) : (
                      <a href="https://t.me/DailyAssets" target="_blank" className="by" onClick={() => {
                        setTimeout(() =>  setClaim([...claim, 3]), 2000)
                      }}>Subscribe </a>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </>
      )}
            {openTask4 && (
        <>
          <div
            className="black-zone"
            onClick={() => {
              setGoBack(true);
              setTimeout(() => {
                setOpenTask4(false);
                onHandleNavbar();
                setGoBack(false);
              }, "500");
            }}
            style={goBack ? { display: "none" } : null}
          ></div>
          <div
            className="window"
            style={
              goBack
                ? {
                    animation: "window_back 0.6s",
                    bottom: "-300px",
                  }
                : null
            }
          >
            <div className="window_data">
              <img src={li} width={"100%"} alt="" />
              <div style={{ padding: "16px", height: "100%" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <X
                  className="close_x"
                    style={{ opacity: "12%" }}
                    onClick={() => {
                      setGoBack(true);
                      setTimeout(() => {
                        setOpenTask4(false);
                        onHandleNavbar();
                        setGoBack(false);
                      }, "500");
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "relative",
                    height: "100%",
                    textAlign: "center",
                  }}
                >
                  <div>
                    <Tg style={{width: '80px', height: '80px'}}/>
                    <p className="wnd_header">Subscribe Deploy</p>
                    <span className="wnd_text">
                    You can invite friends through your referral link and get bonuses
                    </span>
                  </div>
                  {
                    claim.includes(4) ? (
                      <button className="by" disabled={tasks.some(e => e.task === 4)}  onClick={() => 
                      {
                        completeTask(4)
                        setGoBack(true);
                        setTimeout(() => {
                          setOpenTask4(false);
                          onHandleNavbar();
                          setGoBack(false);
                        }, "500");
                      }
                      }>
                      Claim
                    </button>
                    ) : (
                      <a href="https://t.me/DeployTG" target="_blank" className="by" onClick={() => {
                        setTimeout(() =>  setClaim([...claim, 4]), 2000)
                      }}>Subscribe </a>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {openTask5 && (
        <>
          <div
            className="black-zone"
            onClick={() => {
              setGoBack(true);
              setTimeout(() => {
                setOpenTask5(false);
                onHandleNavbar();
                setGoBack(false);
              }, "500");
            }}
            style={goBack ? { display: "none" } : null}
          ></div>
          <div
            className="window"
            style={
              goBack
                ? {
                    animation: "window_back 0.6s",
                    bottom: "-300px",
                  }
                : null
            }
          >
            <div className="window_data">
              <img src={li} width={"100%"} alt="" />
              <div style={{ padding: "16px", height: "100%" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <X
                  className="close_x"
                    style={{ opacity: "12%" }}
                    onClick={() => {
                      setGoBack(true);
                      setTimeout(() => {
                        setOpenTask5(false);
                        onHandleNavbar();
                        setGoBack(false);
                      }, "500");
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "relative",
                    height: "100%",
                    textAlign: "center",
                  }}
                >
                  <div>
                    <Twitter style={{width: '80px', height: '80px'}}/>
                    <p className="wnd_header">Follow Nise Twitter</p>
                    <span className="wnd_text">
                    You can invite friends through your referral link and get bonuses
                    </span>
                  </div>
                  {
                    claim.includes(5) ? (
                      <button className="by" disabled={tasks.some(e => e.task === 5)}  onClick={() => {
                        completeTask(5)
                        setGoBack(true);
                        setTimeout(() => {
                          setOpenTask5(false);
                          onHandleNavbar();
                          setGoBack(false);
                        }, "500");
                        }}>
                      Claim
                    </button>
                    ) : (
                      <a href="https://x.com/NiseApp" target="_blank" className="by" onClick={() => {
                        setTimeout(() =>  setClaim([...claim, 5]), 2000)
                      }}>Follow </a>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {openTask6 && (
        <>
          <div
            className="black-zone"
            onClick={() => {
              setGoBack(true);
              setTimeout(() => {
                setOpenTask6(false);
                onHandleNavbar();
                setGoBack(false);
              }, "500");
            }}
            style={goBack ? { display: "none" } : null}
          ></div>
          <div
            className="window"
            style={
              goBack
                ? {
                    animation: "window_back 0.6s",
                    bottom: "-300px",
                  }
                : null
            }
          >
            <div className="window_data">
              <img src={li} width={"100%"} alt="" />
              <div style={{ padding: "16px", height: "100%" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <X
                  className="close_x"
                    style={{ opacity: "12%" }}
                    onClick={() => {
                      setGoBack(true);
                      setTimeout(() => {
                        setOpenTask6(false);
                        onHandleNavbar();
                        setGoBack(false);
                      }, "500");
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "relative",
                    height: "100%",
                    textAlign: "center",
                  }}
                >
                  <div>
                    <Tg style={{width: '80px', height: '80px'}}/>
                    <p className="wnd_header">Subscribe DA Media</p>
                    <span className="wnd_text">
                    You can invite friends through your referral link and get bonuses
                    </span>
                  </div>
                  {
                    claim.includes(6) ? (
                      <button className="by" disabled={tasks.some(e => e.task === 6)}  onClick={() => {
                        completeTask(6)
                        setGoBack(true);
                        setTimeout(() => {
                          setOpenTask6(false);
                          onHandleNavbar();
                          setGoBack(false);
                        }, "500");
                      }}>
                      Claim
                    </button>
                    ) : (
                      <a href="https://t.me/DailyAssetsMedia" target="_blank" className="by" onClick={() => {
                        setTimeout(() =>  setClaim([...claim, 6]), 2000)
                      }}>Subscribe </a>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </>
      )}
        {showTickets && (
        <>
          <div
            className="black-zone"
            style={goBack ? { display: "none" } : null}
            onClick={() => {
              setGoBack(true);
              setTimeout(() => {
                setShowTickets(false);
                onHandleNavbar();
                setGoBack(false);
              }, "600");
            }}
          ></div>
          <div
            className="window"
            style={
              goBack
                ? {
                    maxHeight: "280px",
                    animation: "window_back 0.6s",
                    bottom: "-300px",
                  }
                : { maxHeight: "280px" }
            }
          >
            <div className="window_data">
              <img src={li} width={"100%"} alt="" />
              <div style={{ padding: "16px", height: "100%" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <X
                  className="close_x"
                    style={{ opacity: "12%" }}
                    onClick={() => {
                      setGoBack(true);
                      setTimeout(() => {
                        setShowTickets(false);
                        onHandleNavbar();
                        setGoBack(false);
                      }, "600");
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "relative",
                    height: "100%",
                    textAlign: "center",
                  }}
                >
                  <div>
                    <Jetton />
                    <p className="wnd_header">Game pass</p>
                    <span className="wnd_text">
                      You can invite friends through your referral link and get
                      bonuses
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {showKey && (
        <>
          <div
            style={goBack ? { display: "none" } : null}
            className="black-zone"
            onClick={() => {
              setGoBack(true);
              setTimeout(() => {
                setShowKey(false);
                onHandleNavbar();
                setGoBack(false);
              }, "600");
            }}
          ></div>
          <div
            className="window"
            style={
              goBack
                ? {
                    maxHeight: "280px",
                    animation: "window_back 0.6s",
                    bottom: "-300px",
                  }
                : { maxHeight: "280px" }
            }
          >
            <div className="window_data">
              <img src={li} width={"100%"} alt="" />
              <div style={{ padding: "16px", height: "100%" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <X
                    className="close_x"
                    style={{ opacity: "12%" }}
                    onClick={() => {
                      setGoBack(true);
                      setTimeout(() => {
                        setShowKey(false);
                        onHandleNavbar();
                        setGoBack(false);
                      }, "600");
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "relative",
                    height: "100%",
                    textAlign: "center",
                  }}
                >
                  <div>
                    <Key />
                    <p className="wnd_header">Box Key</p>
                    <span className="wnd_text">
                      You can invite friends through your referral link and get
                      bonuses
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    <div className="pg">
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <UserName />
        <div style={{ display: "flex", gap: "6px" }}>
          <UserTickets onTickets={() => {
                    if (!goBack) {
                      setShowTickets(true);
                      onHandleNavbar();
                    }
                  }}/>
          <UserKeys onShowKey={() => {
                    if (!goBack) {
                      setShowKey(true);
                      onHandleNavbar();
                    }
                  }}/>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="ts_texts">
          <div className="tss">
            <Coin />
            <Pluss />
            <Coin1 />
          </div>
          <p>Earn more coins and keys</p>
          <span>
            Complete tasks from the list to earn coins and keys. Keep an eye on
            the section, new tasks will appear here
          </span>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <p className="pg_header_s">Tasks</p>
        <p style={{ fontSize: "15px", color: "#FFFFFF66" }}>{7 - tasks.length}</p>
      </div>
      <div className="items_pg">
        {!tasks.some(e => e.task === 1) && (
        <div className="play" onClick={() => {
          if (!goBack) {
            setOpenTask1(true);
            onHandleNavbar();
          }
        }}>
          <div>
            <div className="fw">
              <YouTube />
            </div>
            <div className="flw_texts">
              <p>Follow Nise YouTube</p>
              <span>500 NISE + 0.1 KEY</span>
            </div>
          </div>
          <Arw />
        </div>
        ) }
        {
          !tasks.some(e => e.task === 2) && (
        <div className="play" onClick={() => {
          if (!goBack) {
            setOpenTask2(true);
            onHandleNavbar();
          }
        }}>
          <div>
            <div className="fw">
              <Tg />
            </div>
            <div className="flw_texts">
              <p>Subscribe Nise</p>
              <span>500 NISE + 0.1 KEY</span>
            </div>
          </div>
          <Arw />
        </div>
          )
        }
        {
           !tasks.some(e => e.task === 3) && (
        <div className="play" onClick={() => {
          if (!goBack) {
            setOpenTask3(true);
            onHandleNavbar();
          }
        }}>
          <div>
            <div className="fw">
              <Tg />
            </div>
            <div className="flw_texts">
              <p>Subscribe DA</p>
              <span>500 NISE + 0.1 KEY</span>
            </div>
          </div>
          <Arw />
        </div>
           )
        }
        {
           !tasks.some(e => e.task === 4) && (
        <div className="play" onClick={() => {
          if (!goBack) {
            setOpenTask4(true);
            onHandleNavbar();
          }
        }}>
          <div>
            <div className="fw">
              <Tg />
            </div>
            <div className="flw_texts">
              <p>Subscribe Deploy</p>
              <span>500 NISE + 0.1 KEY</span>
            </div>
          </div>
          <Arw />
        </div>
           )
        }
                {
           !tasks.some(e => e.task === 6) && (
        <div className="play" onClick={() => {
          if (!goBack) {
            setOpenTask6(true);
            onHandleNavbar();
          }
        }}>
          <div>
            <div className="fw">
              <Tg />
            </div>
            <div className="flw_texts">
              <p>Subscribe DA Media</p>
              <span>500 NISE + 0.1 KEY</span>
            </div>
          </div>
          <Arw />
        </div>
           )
        }
        {
           !tasks.some(e => e.task === 5) && (
        <div className="play" onClick={() => {
          if (!goBack) {
            setOpenTask5(true);
            onHandleNavbar();
          }
        }}>
          <div>
            <div className="fw">
              <Twitter />
            </div>
            <div className="flw_texts">
              <p>Follow Nise Twitter</p>
              <span>500 NISE + 0.1 KEY</span>
            </div>
          </div>
          <Arw />
        </div>
           )
        }
        <div>
          <div
            className="start_play"
            style={{
              borderBottomLeftRadius: "0px",
              borderBottomRightRadius: "0px",
            }}
          >
            <div>
              <div className="fw">
                <Ref />
              </div>
              <div className="flw_texts">
                <p>Invite 5 friends</p>
                <span>500 NISE + 0.1 KEY</span>
              </div>
            </div>
            <Arw />
          </div>
          <div className="the_lines">
            <div className="gr_line">
              <div className="wh_line" style={{width: "0px"}}></div>
            </div>
            <span>0 of 5</span>
          </div>
        </div>
        {/* <div
          className="play"
          style={{
            position: "relative",
            overflow: "hidden",
            border: "2px solid var(--border-primary, #FFFFFF0F)"
          }}
        >
          <div className="blured"></div>
          <div className="bl_texts" style={{gap: '8px'}}>
            <Block />
            <p>Invite 10 friends to unlock</p>
          </div>
          <div>
            <div className="fw">
              <Twitter />
            </div>
            <div className="flw_texts">
              <p>Follow Nise Twitter</p>
              <span>500 NISE + 0.1 KEY</span>
            </div>
          </div>
          <Arw />
        </div> */}
        {tasks.length ? <p className="completed">Completed</p> : null}
        {tasks.some(e => e.task === 1) && (
        <div className="play">
          <div>
            <div className="fw">
              <YouTube />
            </div>
            <div className="flw_texts">
              <p>Follow Nise YouTube</p>
              <span>500 NISE + 0.1 KEY</span>
            </div>
          </div>
          <div className="done-div">Done</div>
        </div>
        ) }
        {
          tasks.some(e => e.task === 2) && (
        <div className="play" >
          <div>
            <div className="fw">
              <Tg />
            </div>
            <div className="flw_texts">
              <p>Subscribe Nise</p>
              <span>500 NISE + 0.1 KEY</span>
            </div>
          </div>
          <div className="done-div">Done</div>
        </div>
          )
        }
        {
           tasks.some(e => e.task === 3) && (
        <div className="play">
          <div>
            <div className="fw">
              <Tg />
            </div>
            <div className="flw_texts">
              <p>Subscribe DA</p>
              <span>500 NISE + 0.1 KEY</span>
            </div>
          </div>
          <div className="done-div">Done</div>
        </div>
           )
        }
        {
           tasks.some(e => e.task === 4) && (
        <div className="play" >
          <div>
            <div className="fw">
              <Tg />
            </div>
            <div className="flw_texts">
              <p>Subscribe Deploy</p>
              <span>500 NISE + 0.1 KEY</span>
            </div>
          </div>
          <div className="done-div">Done</div>
        </div>
           )
        }
        {
           tasks.some(e => e.task === 6) && (
        <div className="play" >
          <div>
            <div className="fw">
              <Tg />
            </div>
            <div className="flw_texts">
              <p>Subscribe DA Media</p>
              <span>500 NISE + 0.1 KEY</span>
            </div>
          </div>
          <div className="done-div">Done</div>
        </div>
           )
        }
        {
           tasks.some(e => e.task === 5) && (
        <div className="play" >
          <div>
            <div className="fw">
              <Twitter />
            </div>
            <div className="flw_texts">
              <p>Follow Nise Twitter</p>
              <span>500 NISE + 0.1 KEY</span>
            </div>
          </div>
          <div className="done-div">Done</div>
        </div>
           )
        }
      </div>
    </div>
    </>
  );
};

export default Tasks;
