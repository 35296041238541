import React from "react";
import { ReactComponent as Time } from "../../img/List Item/Left/Items card/Plus time-1.svg";
import { ReactComponent as TimeMinus } from "../../img/List Item/Left/Items card/Minus time.svg";
import { ReactComponent as X2 } from "../../img/List Item/Left/Items card/x2.svg";
import { ReactComponent as Arw } from "../../img/ar.svg";
import { useSelector } from "react-redux";

const Upgrades = ({ open1, open2, open3, open4, onShowError }) => {

  const balance = useSelector(state => state.currentUser.balance)
  const upgrades = useSelector(state => state.currentUser.upgrades)

  return (
    <div className="items_pg">
      <div style={upgrades.some(e => e.upgrade === 1) ? {pointerEvents: 'none'} : null} className="play" onClick={balance >= 600 ? open1 :  onShowError}>
        <div>
          <div className="fw">
            <Time />
          </div>
          <div className="flw_texts">
            <p>Add 2 seconds</p>
            <span>The cost is 600 $NISE</span>
          </div>
        </div>
        {upgrades.some(e => e.upgrade === 1) ? (
        <div className="done-div">Active</div>
        ) : (
          <Arw />
        )}
      </div>
      <div  style={upgrades.some(e => e.upgrade === 2) ? {pointerEvents: 'none'} : null} className="play" onClick={balance >= 5000 ? open2 :  onShowError}>
        <div>
          <div className="fw">
            <TimeMinus />
          </div>
          <div className="flw_texts">
            <p>Remove 30 minutes</p>
            <span>The cost is 5,000 $NISE</span>
          </div>
        </div>
        {upgrades.some(e => e.upgrade === 2) ? (
        <div className="done-div">Active</div>
        ) : (
          <Arw />
        )}
      </div>
      <div style={upgrades.some(e => e.upgrade === 4) ? {pointerEvents: 'none'} : null} className="play" onClick={balance >= 5500 ? open4 :  onShowError}>
        <div>
          <div className="fw">
            <X2 />
          </div>
          <div className="flw_texts">
            <p>x2 to win </p>
            <span>The cost is 5,500 $NISE</span>
          </div>
        </div>
        {upgrades.some(e => e.upgrade === 4) ? (
        <div className="done-div">Active</div>
        ) : (
          <Arw />
        )}
      </div>
    </div>
  );
};

export default Upgrades;
